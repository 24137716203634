import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import 'bootstrap/dist/css/bootstrap.css';

<meta name="description" content="HatedCode, System Administrator, Network engineer, Software Engineer, Student" />

const links = [
  {
    title: 'Snapchat',
    url: 'https://www.snapchat.com/add/hatedcode',
    image: '/snapchat.png',
  },
  {
    title: 'Instagram',
    url: 'https://instagram.com/Hatedcode',
    image: '/instagram.png',
  },
  {
    title: 'BlackTie',
    url: 'https://discord.gg/rSH3TK4uHA',
    image: '/discord.png',
  },
  {
    title: 'Twitter',
    url: 'https://twitter.com/HatedCode',
    image: '/twitter.png',
  },
  {
    title: 'AniList',
    url: 'https://anilist.co/user/HatedCode/',
    image: '/anilist.png',
  },
  {
    title: 'GitHub',
    url: 'https://github.com/HatedCode',
    image: '/github.png',
  },
  {
    title: 'Paste',
    url: 'https://paste.hatedcode.com/',
    image: '/logo192.png',
  },
];

const getRandomBackground = () => {
  const backgrounds = [
    'background1.gif',
    'background2.gif',
    'background3.gif',
    'background4.gif',
    'background5.gif',
    'background6.webp',
  ];
  const randomIndex = Math.floor(Math.random() * backgrounds.length);
  return backgrounds[randomIndex];
};


function App() {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');

  useEffect(() => {
    setBackgroundImageUrl(getRandomBackground());
  }, []);
  
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  return (
    <div
      className={`bg-black d-flex flex-column align-items-center justify-content-between min-vh-100 ${isMobile ? 'mobile' : 'desktop'}`}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    >
      <Container className="text-center" style={{ paddingBottom: '60px', maxWidth: isMobile ? '100%' : '800px' }}>
        <div className="profile-picture mb-3">
          <Image src="/pfp.jpg" alt="Profile" roundedCircle />
        </div>
        <h1 className="text-white mt-3 mb-4">HatedCode</h1>
        <div className="d-flex flex-column align-items-center">
          {links.map((link, index) => (
            <Button
              key={index}
              variant="secondary"
              className="btn-pill btn-lg text-uppercase fw-bold rounded-pill mb-3"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: '100%', maxWidth: '800px', backgroundColor: '#303030' }}
            >
              <Image src={link.image} alt={link.title} className="me-2 img-fluid" style={{ width: '24px', height: '24px' }} />
              {link.title}
            </Button>
          ))}
        </div>
      </Container>
      <footer style={{ backgroundColor: '#303030' }} className="text-white py-2 float-start position-fixed bottom-0 start-0 w-100">
        <div className="container">
          <div className="row">
            <div className="float-end">
              Email: {' '}
              <a
                href="mailto:admin@hatedcode.com"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#ffcc00' }}
                >
                admin@hatedcode.com
              </a>
              <div className="float-end">
              Discord: {' '}
              <a
                href="https://discord.com/users/1049536209053032459"
                target="_blank"
                rel="noreferrer"
                style={{ color: '#ffcc00' }}
              >
                HatedCode
              </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

console.log('Created by HatedCode');

export default App;